

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CHANGE_STATE } from '../../../store/actions';
import { ChangeState, ConservatorshipDetails } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';
import ConservatorshipStateSelect from '../../ConservatorshipStateSelect.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker,
    ConservatorshipStateSelect
  }
})
export default class StateChangeDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CHANGE_STATE) changeSate!: (params: ChangeState) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ChangeState = {
    conservatorship_id: this.conservatorship.id,
    changed: format(Date.now(), 'yyyy-MM-dd'),
    state: 'closed',
    reassignment: false,
    note: ''
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        changed: format(Date.now(), 'yyyy-MM-dd'),
        state: 'closed',
        reassignment: false,
        note: ''
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  async save() {
    this.error = null;

    return await this.changeSate(this.value);
  }
}
