









import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from '@/modules/conservatorship/types';

interface StateItem {
  value: State | null;
  text: string;
}

@Component({ inheritAttrs: false })
export default class ConservatorshipStateSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Boolean, default: false }) filterArchived!: string;

  list: StateItem[] = [
    { value: null, text: 'Alle' },
    { value: 'open', text: 'Offen' },
    { value: 'closed', text: 'Beendet' },
    { value: 'suspended', text: 'Unterbrochen' },
    ...(this.filterArchived ? [] : [{ value: 'archived', text: 'Archiviert' } as StateItem])
  ];

  created() {
    this.$emit('input', this.value);
  }
}
